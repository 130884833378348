import withRoot from "../../utils/withRoot";
import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import List from "../../components/List";

const Courses = props => {
  const courses = props.data.allMarkdownRemark.edges;
  return (
    <Page title="启蒙英语课程">
      <SEO title="启蒙英语,亲子英语课程,亲子口语" />
      <List items={courses} />
    </Page>
  );
};

export const query = graphql`
  query CoursesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/courses/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            imageurl
            title
            path
          }
        }
      }
    }
  }
`;

export default withRoot(Courses);
